<template>
  <super-admin-page-card
    pageName="Manage Navigator Groups"
    iconClass="fa fa-th fa-lg"
  >
    <div>
      <b-tabs v-model="tabIndex">
        <b-tab>
          <template slot="title">
            <h6 class="tab-title font-weight-bold">Manage Group Access</h6>
          </template>

          <v-wait for="loadingNavigatorGroups">
            <template slot="waiting">
              <content-placeholders :rounded="true" class="pt-4">
                <content-placeholders-text
                  :lines="20"
                ></content-placeholders-text>
              </content-placeholders>
            </template>

            <b-row class="justify-content-end mb-4">
              <b-col sm="4">
                <b-form-group label="Search" :label-cols="3" :horizontal="true">
                  <b-form-input v-model="searchText"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-table
              striped
              hover
              :fields="tableFields"
              :filter="searchText"
              :items="filteredNavigatorGroups"
              small
              responsive
            >
              <template v-slot:cell(is_public)="data">
                <c-switch
                  type="text"
                  variant="success"
                  on="Yes"
                  off="No"
                  :pill="true"
                  size="sm"
                  :checked="data.item.is_public"
                  @change="updateAccess(data.item, $event)"
                />
              </template>
            </b-table>
          </v-wait>
        </b-tab>
        <b-tab>
          <template slot="title">
            <h6 class="tab-title font-weight-bold">Manage Group Order</h6>
          </template>

          <v-wait for="loadingNavigatorGroups">
            <template slot="waiting">
              <content-placeholders :rounded="true" class="pt-4">
                <content-placeholders-text
                  :lines="20"
                ></content-placeholders-text>
              </content-placeholders>
            </template>
            <div class="row">
              <div class="col-sm-6">
                <draggable
                  class="list-group"
                  tag="ul"
                  v-model="publicGroups"
                  @change="saveCardPosition"
                  @start="dragStart"
                  @end="dragEnd"
                  :forceFallback="true"
                  v-if="publicGroups.length"
                >
                  <div
                    v-for="(group, index) in publicGroups"
                    class="list-group-item cursor-pointer"
                    :key="group.id"
                  >
                    {{ group.name }}
                    <div class="d-inline float-right index-icon px-2">
                      {{ index + 1 }}
                    </div>
                  </div>
                </draggable>
                <div v-else>
                  <div class="list-group">
                    <div class="list-group-item p-4">
                      No Public Groups to sort
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-wait>
        </b-tab>
      </b-tabs>
    </div>
  </super-admin-page-card>
</template>

<script>
// ui components
import cSwitch from '@/components/Switch'
import draggable from 'vuedraggable'
// api
import advancedQuriesAPI from '@/api/finapps/super_admin/advanced_queries'
// utilities
import _ from 'lodash'

export default {
  name: 'SuperAdminNavigatorGroups',
  components: {
    cSwitch,
    draggable
  },
  data() {
    return {
      tabIndex: 0,
      searchText: null,
      navigatorGroups: [],
      tableFields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'Group Name', sortable: true },
        { key: 'fi_type', label: 'FI Type', sortable: true },
        { key: 'creator', label: 'Created By', sortable: true },
        { key: 'is_public', label: 'Public Access' }
      ]
    }
  },
  created() {
    this.loadInitData()
  },
  computed: {
    publicGroups: {
      get() {
        let publicGroups = this.filteredNavigatorGroups.filter(
          ng => ng.is_public
        )
        return _.sortBy(publicGroups, ['query_order', 'name'])
      },
      set(sortedGroups) {
        sortedGroups.forEach((group, index) => {
          group.query_order = index + 1
        })
      }
    },
    filteredNavigatorGroups() {
      return this.navigatorGroups.filter(ng =>
        ['bank', 'credit_union'].includes(ng.fi_type)
      )
    }
  },
  methods: {
    loadInitData() {
      this.$wait.start('loadingNavigatorGroups')

      advancedQuriesAPI.groups().then(res => {
        this.navigatorGroups = res.navigator_groups
        this.$wait.end('loadingNavigatorGroups')
      })
    },

    updateAccess(group, state) {
      advancedQuriesAPI.updateAccess(group.id, state).then(res => {
        group.is_public = state
        group.query_order = res.query.query_order
        this.$toasted.global.action_success(
          'Group access successfully modified.'
        )
      })
    },
    saveCardPosition() {
      advancedQuriesAPI.updateOrder(this.publicGroups).then(() => {
        this.$toasted.global.action_success('Group Order successfully updated.')
      })
    },

    dragStart() {},
    dragEnd() {}
  }
}
</script>
<style lang="scss" scoped>
.index-icon {
  border-radius: 50%;
  font-weight: bold;
  color: white;
  background-color: #7b7878;
}
</style>
